import {
  GET_INTRODUCE_OFFSET,
  SET_ACTIVE_SECTION,
  SET_ATTACH_FILE,
  SET_IS_LOADED_IMG
} from 'store/actions/uiActions';

const initState = {
  introduceOffset: 0,
  activeSection: 'introduce',
  attachFile: null,
  isLoadedImg: false
};

export default function uiReducer(state = initState, actions) {
  switch (actions.type) {
    case GET_INTRODUCE_OFFSET:
      return {
        ...state,
        introduceOffset: actions.payload
      };
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: actions.payload
      };
    case SET_ATTACH_FILE:
      return {
        ...state,
        attachFile: actions.payload
      };
    case SET_IS_LOADED_IMG:
      return {
        ...state,
        isLoadedImg: actions.payload
      };
    default:
      return state;
  }
}
