import React from 'react';
import styled from 'styled-components';
import loadingIcon from 'assets/images/icons/loading.svg';

const Wrapper = styled.div`
  width: 100%;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingAll = styled.div`
  display: block;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: black url(${(props) => props.icon}) 50% 50% no-repeat;
`;

const Loading = () => {
  return (
    <Wrapper>
      <div className="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Wrapper>
  );
};

export const LoadingAllPage = () => {
  return <LoadingAll icon={loadingIcon} />;
};

export default Loading;
