import { SET_WORK_SECTION } from 'store/actions/workAction';

const initState = {
  workSection: ''
};

export default function workReducer(state = initState, actions) {
  switch (actions.type) {
    case SET_WORK_SECTION:
      return {
        ...state,
        workSection: actions.payload
      };
    default:
      return state;
  }
}
