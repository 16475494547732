export const BASE_URL = 'https://www.per-fectiv.com';

const domainURL = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

export const BASE_API_URL = domainURL.includes('localhost')
  ? 'http://localhost:8888/graphql'
  : 'https://beta.per-fectiv.com/graphql';

// export const BASE_API_URL = 'https://beta.per-fectiv.com/graphql';
export const API_SEND_MAIL = `${BASE_URL}/web-admin/api/send_mail`;
