export const GET_INTRODUCE_OFFSET = 'GET_INTRODUCE_OFFSET';
export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export const SET_ATTACH_FILE = 'SET_ATTACH_FILE';
export const SET_IS_LOADED_IMG = 'SET_IS_LOADED_IMG';

export const getIntroduceOffset = (payload) => {
  return {
    type: GET_INTRODUCE_OFFSET,
    payload
  };
};

export const setActiveSection = (payload) => {
  return {
    type: SET_ACTIVE_SECTION,
    payload
  };
};

export const setAttachFile = (payload) => {
  return {
    type: SET_ATTACH_FILE,
    payload
  };
};

export const setIsLoadedImg = (payload) => {
  return {
    type: SET_IS_LOADED_IMG,
    payload
  };
};
