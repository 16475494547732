import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import {
  routerMiddleware,
  connectRouter,
  ConnectedRouter
} from 'connected-react-router';
import { applyMiddleware, compose, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost';
import { LoadingAllPage } from 'components/commons/Loading';
import history from 'utils/history';
import { BASE_API_URL } from 'constants/constants';
import uiReducer from 'store/reducers/ui';
import workReducer from 'store/reducers/work';
import './styles/index.scss';
import 'slick-carousel/slick/slick.css';

const Layout = React.lazy(() => import('pages/Layout'));

const reducers = combineReducers({
  router: connectRouter(history),
  ui: uiReducer,
  work: workReducer
});

const store = createStore(
  reducers,
  compose(applyMiddleware(routerMiddleware(history), thunk))
);

const httpLink = new HttpLink({ uri: BASE_API_URL });

const apollo = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

function App() {
  return (
		<ApolloProvider client={apollo} >
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<Suspense fallback={<LoadingAllPage />}>
						<Layout />
					</Suspense>
				</ConnectedRouter>
			</Provider>
		</ApolloProvider>
  );
}

export default App;
